import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import './index.css';
import { createRoot } from "react-dom/client";
import App from './components/App';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(<App />);
