import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const LoginPage: React.FC = () => {
    const [hash, setHash] = useState('');
    const navigate = useNavigate();

    const backendDomain = process.env.REACT_APP_BACKEND_DOMAIN;

    const handleLogin = async () => {
        const response = await fetch(`${backendDomain}/api/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ hash })
        });

        if (response.ok) {
            const data = await response.json();
            navigate(`/dashboard/${data.groupId}`);
        } else {
            alert('Invalid group hash');
        }
    };

    return (
        <div className="d-flex justify-content-center align-items-center vh-100">
            <div className="card p-4" style={{ width: '100%', maxWidth: '400px' }}>
                <div className="card-body">
                    <h3 className="card-title text-center mb-4">Login</h3>
                    <div className="mb-3">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter group hash"
                            value={hash}
                            onChange={(e) => setHash(e.target.value)}
                        />
                    </div>
                    <button className="btn btn-primary w-100" onClick={handleLogin}>
                        Login
                    </button>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
